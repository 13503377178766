import React from "react"
import { graphql } from "gatsby"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
// components
import {
    FirstScreen
} from '../components/Blog'
// global
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ua/global.yaml"
import Pagination from "../ui-kit/Pagination"

const Blog = ({data, location, pageContext}) => {
    const posts = data.blog.edges
    const {humanPageNumber, numberOfPages, previousPagePath, nextPagePath} = pageContext

    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/blog',
            title: YAMLGlobal.menu.blog
        }
    ]
    
    const blogLocal = {
        langBase: "/ua",
        YAMLGlobal,
    }
    
    return(
        <Layout local={blogLocal} location={location}>
            <SEO title={`${data.seo.title} | Сторінка: ${humanPageNumber}/${numberOfPages}`} description={data.seo.description_ua} lang='ua' />
            <Breadcrumbs list={list} />
            <FirstScreen 
                articles={posts} 
                Pagination={() => <Pagination
                    parentPath='/ua/blog'
                    nextPage={nextPagePath}
                    previousPage={previousPagePath}
                    countOfPages={numberOfPages}
                    currentPage={humanPageNumber}
                />}
            />
            <FormOrder wave="white"/>
        </Layout>
    )
}

export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        seo: directusBlog {
            title_ua
            description_ua
        }
        blog: allDirectusBlog(
            sort: {fields: created_on, order: DESC}
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    id
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    title_ua
                    slug
                    description_ua
                    created_on(formatString: "DD.MM.YYYY")
                }
            }
        }
    }
`

export default Blog;